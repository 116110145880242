<script>
import {
  BButton,
  BCol,
  BForm,
  BFormInput,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'OrderPage',
  components: {
    BButton,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      paymentMethodOptions: [
        {
          value: 'on_receipt',
          text: 'Наличными',
        },
        {
          value: 'online',
          text: 'Онлайн',
        },
      ],
      statusOptions: [
        {
          value: 'completed',
          text: 'Завершён',
        },
        {
          value: 'confirmed',
          text: 'Подтверждён',
        },
        {
          value: 'pending',
          text: 'В ожидании',
        },
        {
          value: 'cancelled',
          text: 'Отменен',
        },
      ],
      columns: [
        {
          label: 'Код товара',
          field: 'code',
        },
        {
          label: 'Название',
          field: 'name',
        },
        {
          label: 'Модель',
          field: 'model',
        },
        {
          label: 'Бренд',
          field: 'brand',
        },
        {
          label: 'Количество',
          field: 'orderProduct.count',
        },
        {
          label: 'Стоимость за единицу',
          field: this.formatCost,
        },
        {
          label: 'Общая стоимость',
          field: 'orderProduct.cost',
          formatFn: this.formatPrice,
        },
      ],
      order: null,
      cost: null,
      orderDate: null,
      isLoading: true,
    }
  },
  created() {
    if (!this.$route.params.id) {
      this.$router.push({ name: 'shop-orders' })
      this.isLoading = false
      return
    }

    this.$store
      .dispatch('shop/fetchOrderById', this.$route.params.id)
      .then(() => {
        this.order = this.$store.getters['shop/GET_SELECTED_ORDER']

        this.cost = Math.trunc(Number(this.order.cost) / 100)
        this.orderDate = new Date(this.order.createdAt).toLocaleDateString('ru')

        this.isLoading = false
      })
  },
  methods: {
    formatPrice(price) {
      return Math.trunc(Number(price) / 100)
    },
    formatCost(row) {
      return Math.trunc(
        Number(row.orderProduct.cost / row.orderProduct.count) / 100,
      )
    },
    saveItem() {
      this.isLoading = true
      this.$store
        .dispatch('shop/updateOrder', {
          ...this.order,
          cost: this.cost * 100,
        })
        .then(res => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Уведомление',
              icon: 'BellIcon',
              text: res.data.message,
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="order"
      class="order-details"
    >
      <div class="card">
        <div class="card-header">
          <h1 class="card-title mb-2">
            Информация о заказе
          </h1>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1 save-button"
            :disabled="isLoading"
            @click="saveItem"
          >
            Сохранить
          </b-button>
        </div>

        <div class="card-body">
          <b-form @submit.prevent>
            <div class="customer-block">
              <h2 class="card-subtitle mb-1">
                Заказчик
              </h2>
              <b-row class="mb-3">
                <b-col md="6">
                  <label for="customer-name">Имя</label>
                  <b-form-input
                    id="customer-name"
                    class="mb-1"
                    :value="order.user.name"
                    placeholder="Имя"
                    readonly
                  />

                  <label for="customer-surname">Фамилия</label>
                  <b-form-input
                    id="customer-surname"
                    :value="order.user.surname"
                    placeholder="Фамилия"
                    readonly
                  />
                </b-col>

                <b-col md="6">
                  <label for="customer-phone">Номер телефона</label>
                  <b-form-input
                    id="customer-phone"
                    class="mb-1"
                    :value="order.user.number"
                    placeholder="Телефон"
                    readonly
                  />

                  <label for="customer-email">Email</label>
                  <b-form-input
                    id="customer-email"
                    :value="order.user.email"
                    placeholder="Email"
                    readonly
                  />
                </b-col>
              </b-row>
            </div>

            <div class="order-block">
              <h2 class="card-subtitle mb-1">
                Информация о заказе
              </h2>
              <b-row class="mb-3">
                <b-col md="6">
                  <label for="order-cost">Стоимость заказа</label>
                  <b-form-input
                    id="order-cost"
                    class="mb-1"
                    :value="cost"
                    placeholder="Стоимость"
                    readonly
                  />

                  <label
                    for="order-creation-date"
                  >Дата оформления заказа</label>
                  <b-form-input
                    id="order-creation-date"
                    class="mb-1"
                    :value="orderDate"
                    placeholder="Дата оформления заказа"
                    readonly
                  />
                </b-col>

                <b-col md="6">
                  <label for="order-status">Статус</label>
                  <b-form-select
                    id="order-status"
                    v-model="order.status"
                    class="mb-1"
                    :options="statusOptions"
                  />

                  <label for="order-payment-type">Тип оплаты</label>
                  <b-form-select
                    id="order-payment-type"
                    v-model="order.payment_method"
                    :options="paymentMethodOptions"
                    disabled
                  />
                </b-col>
              </b-row>
            </div>

            <div class="product-block" />
            <h2 class="card-subtitle mb-1">
              Товары
            </h2>
            <vue-good-table
              v-if="order.orderItems.length"
              :columns="columns"
              :rows="order.orderItems"
            />
            <span v-else> Список товаров пуст </span>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
